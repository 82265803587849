<template>
  <b-card title="Listar Motivos">
    <listarMotivo />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarMotivo from '../../Listagens/Motivos/ListarMotivos.vue'

export default {
  components: {
    BCard,
    listarMotivo,
  },
}
</script>
